export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "zh": {
        "title": {
          "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开关"])},
          "brightness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亮度"])},
          "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音量"])},
          "effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灯效"])},
          "colorTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色温"])},
          "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])}
        },
        "power": {
          "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亮屏"])},
          "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["息屏"])}
        },
        "colorTemperature": {
          "sunlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日光"])},
          "moonlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月光"])}
        }
      },
      "en": {
        "title": {
          "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
          "brightness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brightness"])},
          "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volume"])},
          "effect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effect"])},
          "colorTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ColorTemperature"])},
          "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])}
        },
        "power": {
          "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
          "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])}
        },
        "colorTemperature": {
          "sunlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunlight"])},
          "moonlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moonlight"])}
        }
      }
    }
  })
}
